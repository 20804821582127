@font-face {
  font-family: "Gilroy";
  font-display: block;
  src:
    local("Gilroy"),
    url(./fonts/gilroy.otf) format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Moderna";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    local("Moderna"),
    url("./fonts/moderna.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Abril Fatface";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    local("Abril Fatface"),
    url("./fonts/AbrilFatface-Regular.ttf") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Noto Serif";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    local("Noto Serif"),
    url("./fonts/NotoSerif-Regular.ttf") format("truetype");
}

*,
*:after,
*:before {
  transition:
    background-color 0.5s,
    box-shadow 0.5s,
    border-color 0.5s,
    transform 0.25s;
}

@keyframes zoomIn {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes shake {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-2px) rotate(-4deg);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateY(-2px) rotate(4deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}
